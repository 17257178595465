.sf-stats-title,
.sf-stats-heading {
  text-align: center;
}

.sf-stats-big-numbers {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.sf-stats-big-number {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
  border: 2px solid black;
  border-radius: 1em;
  margin: 2em;
}

.sf-stats-big-number-number {
  font-size: 2rem;
}
